const Noodl = require('@noodl/noodl-sdk');
import $ from 'jquery';
import 'bootstrap';
import 'summernote';

class MyCustomReactComponent extends React.Component {
    componentDidMount() {
        $('#summernote').summernote({
            // Summernote options go here
        });
    }

    componentWillUnmount() {
        $('#summernote').summernote('destroy');
    }

    render() {
        return (
            <div id="summernote"></div>
        );
    }
}

const MyCustomReactComponentNode = Noodl.defineReactNode({
    name: 'summernote',
    category: 'UI Elements',
    getReactComponent() {
        return MyCustomReactComponent;
    },
    inputProps: {
        deltaIn: { 
            type: 'string', 
            default: JSON.stringify({}), 
            set(newValue, component) {
                component.setProps({ deltaIn: newValue });
            }
        },
    },

    outputProps: {
        deltaOut: { 
            type: 'string', 
            displayName: 'Delta Out', 
            group: 'Outputs',
            get(component) {
                return component.props.deltaOut;
            }
        },
			// ... other outputs ...
		},
})

Noodl.defineModule({
	reactNodes: [
		MyCustomReactComponentNode
	],
	nodes:[

	],
	setup() {
		//this is called once on startup
	}
});